<template>
  <div>
    <select v-model="selected" @change="onChange($event)">
      <option v-for="(item, index) in devices" :value="item.id" :key="index">
        {{ item.id }}
      </option>
    </select>
    <select v-model="selectedType" @change="onChange($event)">
      <option value="DeviceDownRSSI">Device RSSI</option>
      <option value="DeviceDownSNR">Device SNR</option>
      <option value="ServerUpRSSI">Server RSSI</option>
      <option value="ServerUpSNR">Server SNR</option>
    </select>
    <div class="margin-top-5">
      <label id="from_label"> From:</label>
      <input type="datetime-local" id="from_date" value="2017-06-01" @change="onChange($event)" />
      <label id="to_label">To:</label>
      <input type="datetime-local" id="to_date" value="2017-06-01" @change="onChange($event)" />
    </div>
    <p v-if="loading">Still loading..</p>
    <p v-if="error"></p>
  </div>
  <div id="map"></div>
  <div class="quake-info">
    <div><strong>device RSSI:</strong>&nbsp;<span id="dev-rssi"></span></div>
    <div><strong>device SNR:</strong>&nbsp;<span id="dev-snr"></span></div>
    <div><strong>server RSSI:</strong>&nbsp;<span id="server-rssi"></span></div>
    <div><strong>server SNR:</strong>&nbsp;<span id="server-snr"></span></div>
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { onMounted, ref, markRaw } from 'vue'
const interpolateHeatmapLayer = require('../components/interpolateheatmaplayer_custom')

const mapInstance = ref(null)
export default {
  data: function () {
    return {
      selected: '537061726b6f6e61',
      selectedType: 'DeviceDownRSSI',
    }
  },
  methods: {
    onChange: async function () {
      this.changeMap()
    },
    changeMap: async function () {
      console.log(this.selected)
      const from_time = document.getElementById('from_date')
      console.log(from_time.value)
      const to_time = document.getElementById('to_date')
      console.log(
        '/api/logs/' + this.selected + '?from=' + from_time.value + '&to=' + to_time.value
      )

      const response = await fetch(
        '/api/logs/' + this.selected + '?from=' + from_time.value + '&to=' + to_time.value
      )
      const data = await response.json()
      // save full coordinate list for later
      console.log(data)

      const interpolatedPoints = data.features.map((item) => {
        return {
          lat: item.geometry.coordinates[1],
          lon: item.geometry.coordinates[0],
          val: item.properties[this.selectedType],
        }
      })

      // add it to the map
      //mapInstance.value.removeSource('trace')
      console.log(mapInstance.value.getSource('trace'))

      mapInstance.value.getSource('trace').setData(data)

      if (mapInstance.value.getLayer('heatmap-layer')) {
        mapInstance.value.removeLayer('heatmap-layer')
      }
      let heatmapLayer = interpolateHeatmapLayer.create({
        layerId: 'heatmap-layer',
        points: interpolatedPoints,
      })
      console.log(heatmapLayer)
      mapInstance.value.addLayer(heatmapLayer)

      if (mapInstance.value.getLayer('trace1')) {
        mapInstance.value.removeLayer('trace1')
      }
      mapInstance.value.addLayer({
        id: 'trace1',
        type: 'circle',
        source: 'trace',
        minzoom: 7,
        paint: {
          // Size circle radius by earthquake magnitude and zoom level
          'circle-radius': [
            'interpolate',
            ['linear'],
            ['zoom'],
            7,
            ['interpolate', ['linear'], ['get', 'DeviceDownRSSI'], 1, 1, 6, 4],
            16,
            ['interpolate', ['linear'], ['get', 'DeviceDownRSSI'], 1, 5, 6, 50],
          ],
          // Color circle by earthquake magnitude
          'circle-color': [
            'interpolate',
            ['linear'],
            ['get', 'DeviceDownRSSI'],
            -120,
            'rgba(33,102,172,0)',
            -110,
            'rgb(103,169,207)',
            -100,
            'rgb(209,229,240)',
            -85,
            'rgb(253,219,199)',
            -70,
            'rgb(239,138,98)',
            -50,
            'rgb(178,24,43)',
          ],
          'circle-stroke-color': 'white',
          'circle-stroke-width': 1,
          // Transition from heatmap to circle layer by zoom level
          'circle-opacity': ['interpolate', ['linear'], ['zoom'], 7, 0, 8, 1],
        },
      })
    },
  },
  setup() {
    const devices = ref([])
    const loading = ref(true)
    const error = ref(null)

    const getDevices = () => {
      loading.value = true
      fetch('https://lora-heatmap.uat.ding.hu/api/available-deveuis/', {
        method: 'get',
      })
        .then((res) => {
          console.log(res)
          // a non-200 response code
          if (!res.ok) {
            // create error instance with HTTP status text
            const error = new Error(res.statusText)
            error.json = res.json()
            throw error
          }

          return res.json()
        })
        .then((data) => {
          devices.value = data
        })
        .catch((err) => {
          error.value = err
          // In case a custom JSON error response was provided
          if (err.json) {
            return err.json.then((json) => {
              // set the JSON response message
              error.value.message = json.message
            })
          }
        })
        .then(() => {
          loading.value = false
        })
    }
    onMounted(() => {
      var now = new Date()
      now.setMinutes(now.getMinutes() - now.getTimezoneOffset())

      /* remove second/millisecond if needed - credit ref. https://stackoverflow.com/questions/24468518/html5-input-datetime-local-default-value-of-today-and-current-time#comment112871765_60884408 */
      now.setMilliseconds(null)
      now.setSeconds(null)
      document.getElementById('from_date').value = now.toISOString().slice(0, -1)
      document.getElementById('to_date').value = now.toISOString().slice(0, -1)
      mapboxgl.accessToken =
        'pk.eyJ1IjoiZGluZ2NvIiwiYSI6ImNpemlhaHF0ZDAwM2gzM28zN29wenh6czMifQ.YXY-Gh10-E1iY8UGuHwaKw'

      mapInstance.value = markRaw(
        new mapboxgl.Map({
          container: 'map',
          style: 'mapbox://styles/mapbox/light-v10',
          zoom: 16.5,
          maxzoom: 21,
          center: [19.06937972526686, 47.490447405776266],
        })
      )
      window.mapboxgl = mapboxgl

      mapInstance.value.on('load', async () => {
        if (!mapInstance.value.getSource('trace')) {
          mapInstance.value.addSource('trace', { type: 'geojson', data: '' })
        }

        const deviceRSSI = document.getElementById('dev-rssi')
        const deviceSNR = document.getElementById('dev-snr')
        const serverRSSI = document.getElementById('server-rssi')
        const serverSNR = document.getElementById('server-snr')
        let quakeID = null
        mapInstance.value.on('mousemove', 'trace1', (event) => {
          mapInstance.value.getCanvas().style.cursor = 'pointer'
          if (event.features.length === 0) return
          // Set variables equal to the current feature's magnitude, location, and time
          const deviveRSSI_val = event.features[0].properties.DeviceDownRSSI
          const deviceSNR_val = event.features[0].properties.DeviceDownSNR
          const serverRSSI_val = event.features[0].properties.ServerUpRSSI
          const serverSNR_val = event.features[0].properties.ServerUpSNR
          console.log(event.features[0].properties)

          // Display the magnitude, location, and time in the sidebar

          deviceRSSI.textContent = deviveRSSI_val
          deviceSNR.textContent = deviceSNR_val
          serverRSSI.textContent = serverRSSI_val
          serverSNR.textContent = serverSNR_val

          // When the mouse moves over the earthquakes-viz layer, update the
          // feature state for the feature under the mouse
          if (quakeID) {
            mapInstance.value.removeFeatureState({
              source: 'trace',
              id: quakeID,
            })
          }
          quakeID = 1

          mapInstance.value.setFeatureState(
            {
              source: 'trace',
              id: quakeID,
            },
            {
              hover: true,
            }
          )
        })
        mapInstance.value.on('mouseleave', 'trace1', () => {
          if (quakeID) {
            mapInstance.value.setFeatureState(
              {
                source: 'trace',
                id: quakeID,
              },
              {
                hover: false,
              }
            )
          }
          quakeID = null
          // Remove the information from the previously hovered feature from the sidebar
          deviceRSSI.textContent = ''
          deviceSNR.textContent = ''
          serverRSSI.textContent = ''
          serverSNR.textContent = ''
          // Reset the cursor style
          mapInstance.value.getCanvas().style.cursor = ''
        })
      })

      getDevices()
    })

    return {
      devices,
      loading,
      error,
    }
  },
}
</script>

<style>
#map {
  height: 100vh;
  position: absolute;
  top: 140px;
  width: 100%;
  bottom: 0;
}
#from_label {
  margin-left: 20px;
}
#to_label {
  margin-left: 10px;
}
.quake-info {
  top: 140px;
  position: absolute;
  font-family: sans-serif;
  margin-top: 5px;
  margin-left: 5px;
  padding: 5px;
  width: 30%;
  border: 2px solid black;
  font-size: 14px;
  color: #222;
  background-color: #fff;
  border-radius: 3px;
}
</style>
